import { configureStore } from '@reduxjs/toolkit'
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query'
import { homePageService } from './homePageService'
import popupReducer from './reducerslice';
import otppopupStatusReducer from './otpSlice';
import loggedinslice from './loggedinslice';
export const store = configureStore({
    reducer: {
        // Add the generated reducer as a specific top-level slice
        [homePageService.reducerPath]: homePageService.reducer,
        loginPopup: popupReducer,
        loggedIn: loggedinslice,
        otpPopup: otppopupStatusReducer
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(homePageService.middleware),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)