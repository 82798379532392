import React, { useEffect, useRef } from 'react';

const TawkToWidget = () => {
    const tawkRef = useRef(null);

    useEffect(() => {
        var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
        (function () {
            var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            s1.src = 'https://embed.tawk.to/66bf2c19146b7af4a43b1cbe/1i5dc8hep';
            s1.charset = 'UTF-8';
            s1.setAttribute('crossorigin', '*');
            s0.parentNode.insertBefore(s1, s0);
        })();
        setTimeout(() => {
            if (document.getElementsByTagName('iframe')[0]) {
                let parentElement = document.getElementsByTagName('iframe')[0].parentElement;
                parentElement.style.setProperty('display', 'block', 'important');
            }
        }, 1000)
    }, []);
};

export default TawkToWidget;
