import React, { useState } from 'react'
import './LoginPopup.scss';
import Modal from 'react-bootstrap/Modal';
import closesvg from '../../assets/images/close.svg'
import { useSelector, useDispatch } from 'react-redux';
import { popupValue } from '../../services/reducerslice';
import { homePageService, useLoginWithOtpMutation, useLoginWithPassMutation, useRegUserMutation, useVerifyOtpMutation } from '../../services/homePageService';
import logoSvg from '../../assets/images/logo.svg';
import { click } from '@testing-library/user-event/dist/click';
import { toast } from 'react-toastify';

function LoginPopup(props: ModalProps) {
    const dispatch = useDispatch();
    const popupState = useSelector(state => state.loginPopup.value);

    //loning state 1:OTP login 2:Password , 3: Register
    const [loginType, setLoginType] = useState(2);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [otpElement, setotpElement] = useState(false);
    const [otp, setOtp] = useState('');
    const [createUser] = useRegUserMutation();
    const [loginWithPass] = useLoginWithPassMutation();
    const [loginWithOtp] = useLoginWithOtpMutation();
    const [verifyOtpCall] = useVerifyOtpMutation();
    const [resendOtpflag, setResendOtpFlag] = useState(false);
    const handleNameChange = (event) => {
        setName(event.target.value);
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }
    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    }
    const handleChangeOtp = (event) => {
        setOtp(event.target.value);
    }
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const handleOtpChange = (event) => {
        setOtp(event.target.value);
    }

    const resendOtp = (event) => {
        setResendOtpFlag(true)
        loginUserWithOtp();
    }
    const verifyLogin = async () => {

        if (otp === '') {
            toast.error('Please enter OTP ');
            return false;
        }

        setIsLoading(true);
        try {

            let pdata = {
                mobile: phone,
                otp: otp,
                type: 3
            }
            // Dispatch the mutation with the new resource data
            const result = await verifyOtpCall(pdata);

            if (!result.data.status) {
                toast.error(result.data.message);
            }
            else if (result.data.status) {
                let resData = result.data;
                localStorage.setItem('name', resData.name)
                localStorage.setItem('email', resData.email)
                localStorage.setItem('phone', resData.phone)
                localStorage.setItem('token', resData.token)
                dispatch(popupValue(false));
                window.location.reload();
            }

            // setCarData(carData => [...carData, { totalKm: result.data }]);
        } catch (error) {
            // Handle error
            console.error('Error creating resource:', error);
        }
        finally {
            setIsLoading(false) //to turn off loading

        }
    }

    const loginWithPassSet = () => {
        setLoginType(2)
        setOtp("");
        setotpElement(false)
    }

    const handleStoreData = async () => {
        let type = '1';
        if (loginType === 2)
            type = '4'
        else if (loginType === 3)
            type = '2'
        setIsLoading(true);
        try {

            let pdata = {
                name: name,
                email: email,
                mobile: phone,
                spassword: password,
                type: type
            }
            // Dispatch the mutation with the new resource data
            const result = await createUser(pdata);

            if (!result.data.status) {
                toast.error(result.data.message);
            }
            else if (result.data.status) {
                let resData = result.data;
                localStorage.setItem('name', resData.name)
                localStorage.setItem('email', resData.email)
                localStorage.setItem('phone', resData.phone)
                localStorage.setItem('token', resData.token)
                dispatch(popupValue(false));
                window.location.reload();
            }

            // setCarData(carData => [...carData, { totalKm: result.data }]);
        } catch (error) {
            // Handle error
            console.error('Error creating resource:', error);
        }
        finally {
            setIsLoading(false) //to turn off loading

        }
    };

    const registerNewAcc = (e) => {
        setLoginType(3)
    }


    const loginUser = async () => {

        if (phone === '') {
            toast.error('Please enter Mobile No');
            return false;
        }

        const phoneRegex = /^\d{10}$/;

        if (!phoneRegex.test(phone)) {
            toast.error('Please enter valid Mobile No');
            return;
        }

        if (password === '') {
            toast.error('Please enter Password');
            return false;
        }

        setIsLoading(true);

        try {

            let pdata = {
                mobile: phone,
                password: password,
                type: 4
            }
            // Dispatch the mutation with the new resource data
            const result = await loginWithPass(pdata);

            if (!result.data.status) {
                toast.error(result.data.message);
            }
            else if (result.data.status) {
                let resData = result.data;
                localStorage.setItem('name', resData.name)
                localStorage.setItem('email', resData.email)
                localStorage.setItem('phone', resData.phone)
                localStorage.setItem('token', resData.token)
                dispatch(popupValue(false));
                window.location.reload();
            }

            // setCarData(carData => [...carData, { totalKm: result.data }]);
        } catch (error) {
            // Handle error
            console.error('Error creating resource:', error);
        }
        finally {
            setIsLoading(false) //to turn off loading

        }

    }

    const loginUserWithOtp = async () => {

        if (phone === '') {
            toast.error('Please enter Mobile No');
            return false;
        }

        const phoneRegex = /^\d{10}$/;

        if (!phoneRegex.test(phone)) {
            toast.error('Please enter valid Mobile No');
            return;
        }

        setIsLoading(true);

        try {

            let pdata = {
                mobile: phone,
                type: 1
            }
            // Dispatch the mutation with the new resource data
            const result = await loginWithOtp(pdata);
            if (!result.data.status) {
                toast.error(result.data.message);
            }
            else if (result.data.status) {
                toast.success('OTP sent successfully');
                setotpElement(true);
            }

            // setCarData(carData => [...carData, { totalKm: result.data }]);
        } catch (error) {
            // Handle error
            console.error('Error creating resource:', error);
        }
        finally {
            setIsLoading(false) //to turn off loading

        }

    }

    const signup = () => {

        if (name === '') {
            toast.error('Please enter name');
            return false;
        }
        if (phone === '') {
            toast.error('Please enter Mobile No');
            return false;
        }
        if (phone !== '') {
            const phoneRegex = /^\d{10}$/;

            if (!phoneRegex.test(phone)) {
                toast.error('Please enter valid Mobile No');
                return;
            }
        }

        if (email === '') {
            toast.error('Please enter email');
            return false;
        }
        if (email !== '') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                toast.error('Please enter valid email');
                return;
            }
        }

        handleStoreData();

    }



    return (
        <>
            <Modal show={popupState} className="custom__modal modal__login" >
                <Modal.Header>

                    <div className='imgclose' onClick={() => {
                        dispatch(popupValue(false));
                    }}>
                        <img src={closesvg} />
                    </div>
                </Modal.Header>
                <Modal.Body>

                    <main className="main main--sign" data-bg="img/bg/bg.png">

                        <div className="sign">
                            <div className="sign__content">
                                <form action="#" className="sign__form">
                                    {loginType !== 3 && (
                                        <div className='login__label' >LOGIN</div>
                                    )}
                                    {loginType === 3 && (
                                        <div className='login__label' >REGISTER</div>
                                    )}<br />
                                    {loginType !== 3 && (
                                        <>
                                            {!otpElement && (
                                                <div className="sign__group">
                                                    <input type="text" className="sign__input" maxLength="10" pattern="[1-9]{1}[0-9]{9}" className="sign__input" placeholder="Mobile" onChange={handlePhoneChange} />
                                                </div>
                                            )}
                                            {/* {otpElement &&  (
                                                <div className="sign__group">
                                                    <input type="text" className="sign__input" maxLength="6" pattern="[1-9]{1}[0-9]{9}" className="sign__input" placeholder="Mobile" onChange={handleChangeOtp} />
                                                </div>
                                            )} */}

                                            {loginType !== 1 && (
                                                <div className="sign__group">
                                                    <input type="password" className="sign__input" placeholder="Password" onChange={handlePasswordChange} />
                                                </div>
                                            )}
                                            {otpElement && loginType === 1 && (
                                                <div className="sign__group">
                                                    <input type="number" className="sign__input" placeholder="OTP" onChange={handleOtpChange} />

                                                    {!resendOtpflag && (
                                                        <div className='resenotp' onClick={() => {
                                                            resendOtp();
                                                        }}>Resend OTP</div>
                                                    )}
                                                </div>
                                            )}

                                            {!otpElement && loginType !== 1 && (
                                                <button className="sign__btn" type="button" onClick={() => {
                                                    loginUser();
                                                }} ><span>Sign in</span></button>
                                            )}
                                            {!otpElement && loginType === 1 && (
                                                <button className="sign__btn" type="button" onClick={() => {
                                                    loginUserWithOtp();
                                                }} ><span>Submit</span></button>
                                            )}
                                            {otpElement && loginType === 1 && (
                                                <button className="sign__btn" type="button" onClick={() => {
                                                    verifyLogin();
                                                }} ><span>Submit</span></button>
                                            )}
                                        </>
                                    )}
                                    {loginType === 3 && (
                                        <>
                                            <div className="sign__group">
                                                <input type="text" className="sign__input" onChange={handleNameChange} placeholder="Name" />
                                            </div>
                                            <div className="sign__group">
                                                <input type="text" maxLength="10" pattern="[1-9]{1}[0-9]{9}" className="sign__input" placeholder="Mobile" onChange={handlePhoneChange} />
                                            </div>
                                            <div className="sign__group">
                                                <input type="text" className="sign__input" placeholder="Email" onChange={handleEmailChange} />
                                            </div>
                                            <div className="sign__group">
                                                <input type="password" className="sign__input" placeholder="Password" onChange={handlePasswordChange} />
                                            </div>
                                            <button className="sign__btn" type="button" onClick={signup}><span>Sign Up</span></button>
                                        </>
                                    )}

                                    {loginType !== 3 && (
                                        <span className="sign__text">Don't have an account? <a onClick={() => {
                                            setLoginType(3);
                                        }}>Sign up!</a></span>
                                    )}
                                    {loginType === 3 && (
                                        <span className="sign__text">Already have an account? <a onClick={() => {
                                            setLoginType(2);
                                        }}>Login</a></span>)}
                                    {loginType !== 1 && (
                                        <span className="sign__text"><a onClick={() => {
                                            setLoginType(1);
                                        }}>Login With OTP</a></span>
                                    )}
                                    {loginType === 1 && (
                                        <span className="sign__text"><a onClick={() => {
                                            loginWithPassSet();
                                        }}>Login With Password</a></span>
                                    )}
                                </form>

                            </div>
                        </div>

                    </main>
                </Modal.Body>
            </Modal >

        </>
    )
}

export default LoginPopup