import { createSlice } from '@reduxjs/toolkit'


export const otppopupSlice = createSlice({
    name: 'otppopup',
    initialState: {
        value: false
    },
    reducers: {
        otppopupStatusSet: (state, action) => {
            state.value = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { otppopupStatusSet } = otppopupSlice.actions

export default otppopupSlice.reducer