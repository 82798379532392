import { createSlice } from '@reduxjs/toolkit'


export const loggedInSlice = createSlice({
    name: 'loggedIn',
    initialState: {
        value: false
    },
    reducers: {
        loggedInStatusSet: (state, action) => {
            state.value = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { loggedInStatusSet } = loggedInSlice.actions

export default loggedInSlice.reducer