import React from 'react';
import { Helmet } from 'react-helmet';

const SchemaOrg = () => {
    const schemaData = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "url": "https://hirecabs.in",
        "logo": "https://www.hirecabs.in/logo/logo.svg",
        "name": "HireCabs Bangalore",
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+91-888-484-8098",
            "contactType": "Customer Service"
        },
        "description": "Book all types of Car,Cab booking online across bangalore in very reasonable rates.",
        "brand": {
            "@type": "Thing",
            "name": "Cab booking online"
        },
    };

    return (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify(schemaData)}
            </script>
        </Helmet>
    );
};

export default SchemaOrg;
