import React from 'react'
import { Outlet, Link } from "react-router-dom";
function NoPage() {
    return (
        <main className="main main--sign" data-bg="img/bg/bg.png">
            <div className="page-404">
                <div className="page-404__wrap">
                    <div className="page-404__content">
                        <h1 className="page-404__title">404</h1>
                        <p className="page-404__text">The page you are looking for not available!</p>
                        <Link to={`/`} className="page-404__btn"><span>go back</span></Link>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default NoPage