// LoadingSkeleton.js
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './LoadingSkeleton.scss';

function LoadingSkeleton() {
    return (
        <>
            <div style={{ padding: '10px' }}>
                <div className="skeleton" style={{ width: '200px', height: '30px' }}></div>
                <div className="skeleton" style={{ width: '100%', height: '15px' }}></div>
                <div className="skeleton" style={{ width: '100%', height: '15px' }}></div>
                <div className="skeleton" style={{ width: '100%', height: '15px' }}></div>
            </div>
            <div style={{ padding: '10px' }}>
                <div className="skeleton" style={{ width: '200px', height: '30px' }}></div>
                <div className="skeleton" style={{ width: '100%', height: '15px' }}></div>
                <div className="skeleton" style={{ width: '100%', height: '15px' }}></div>
                <div className="skeleton" style={{ width: '100%', height: '15px' }}></div>
            </div>
            <div style={{ padding: '10px' }}>
                <div className="skeleton" style={{ width: '200px', height: '30px' }}></div>
                <div className="skeleton" style={{ width: '100%', height: '15px' }}></div>
                <div className="skeleton" style={{ width: '100%', height: '15px' }}></div>
                <div className="skeleton" style={{ width: '100%', height: '15px' }}></div>
            </div>
            <div style={{ padding: '10px' }}>
                <div className="skeleton" style={{ width: '200px', height: '30px' }}></div>
                <div className="skeleton" style={{ width: '100%', height: '15px' }}></div>
                <div className="skeleton" style={{ width: '100%', height: '15px' }}></div>
                <div className="skeleton" style={{ width: '100%', height: '15px' }}></div>
            </div>
            <div style={{ padding: '10px' }}>
                <div className="skeleton" style={{ width: '200px', height: '30px' }}></div>
                <div className="skeleton" style={{ width: '100%', height: '15px' }}></div>
                <div className="skeleton" style={{ width: '100%', height: '15px' }}></div>
                <div className="skeleton" style={{ width: '100%', height: '15px' }}></div>
            </div>
            <div style={{ padding: '10px' }}>
                <div className="skeleton" style={{ width: '200px', height: '30px' }}></div>
                <div className="skeleton" style={{ width: '100%', height: '15px' }}></div>
                <div className="skeleton" style={{ width: '100%', height: '15px' }}></div>
                <div className="skeleton" style={{ width: '100%', height: '15px' }}></div>
            </div>

            <div style={{ padding: '10px' }}>
                <div className="skeleton" style={{ width: '200px', height: '30px' }}></div>
                <div className="skeleton" style={{ width: '100%', height: '15px' }}></div>
                <div className="skeleton" style={{ width: '100%', height: '15px' }}></div>
                <div className="skeleton" style={{ width: '100%', height: '15px' }}></div>
            </div>
            <div style={{ padding: '10px' }}>
                <div className="skeleton" style={{ width: '200px', height: '30px' }}></div>
                <div className="skeleton" style={{ width: '100%', height: '15px' }}></div>
                <div className="skeleton" style={{ width: '100%', height: '15px' }}></div>
                <div className="skeleton" style={{ width: '100%', height: '15px' }}></div>
            </div>

        </>

    );
}

export default LoadingSkeleton;
