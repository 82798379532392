import logo from './logo.svg';
import './App.css';
import Header from './components/Header/Header';
// import About from './pages/About/About';
// import Home from './pages/Home/Home';
// import Help from './pages/Help/Help';
// import Privacy from './pages/Privacy/Privacy';
// import Terms from './pages/Terms/Terms';
// import CarsList from './pages/CarsList/CarsList';
// import Layout from './components/Layout/Layout';
import NoPage from './pages/NoPage/NoPage';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Redirect } from 'react-router'
import LoginPopup from './components/LoginPopup/LoginPopup';
import React, { useRef, Suspense } from 'react'
import 'boxicons';
import { useSelector, useDispatch } from 'react-redux';
import { popupValue } from './services/reducerslice';
import { otppopupStatusSet } from './services/otpSlice';
// import MyAccount from './pages/MyAccount/MyAccount';
import { useState, useEffect } from 'react';
import Contact from './pages/Contact/Contact';
// import AddPartner from './pages/AddPartner/AddPartner';
// import BookCab from './pages/BookCab/BookCab';
import OtpLogin from './components/OtpLogin/OtpLogin'
import { ToastContainer, toast, Flip } from 'react-toastify';
import { loggedInStatusSet } from './services/loggedinslice';
import TawkToWidget from './components/TawkToWidget/TawkToWidget';
import LoadingSkeleton from './components/LoadingSkeleton/LoadingSkeleton';

function App() {
  const tawkMessengerRef = useRef();

  const dispatch = useDispatch();
  const popupState = useSelector(state => state.loginPopup.value);
  const checkLogin = useSelector(state => state.loggedIn.value)

  const [loggedIn, setLoggedIn] = useState(null);

  const LazyLayout = React.lazy(() => import('./components/Layout/Layout'));
  const LazyHome = React.lazy(() => import('./pages/Home/Home'));
  const LazyAbout = React.lazy(() => import('./pages/About/About'));
  const LazyCarsList = React.lazy(() => import('./pages/CarsList/CarsList'));
  const LazyBookCab = React.lazy(() => import('./pages/BookCab/BookCab'));
  const LazyHelp = React.lazy(() => import('./pages/Help/Help'));
  const LazyTerms = React.lazy(() => import('./pages/Terms/Terms'));
  const LazyPrivacy = React.lazy(() => import('./pages/Privacy/Privacy'));
  const LazyContact = React.lazy(() => import('./pages/Contact/Contact'));
  const LazyAddPartner = React.lazy(() => import('./pages/AddPartner/AddPartner'));
  const LazyMyAccount = React.lazy(() => import('./pages/MyAccount/MyAccount'));

  useEffect(() => {
    onLoad();
  }, [checkLogin]);

  async function onLoad() {
    try {
      let token = localStorage.getItem('token');
      if (checkLogin || token !== null) {
        setLoggedIn(true)
      } else {
        setLoggedIn(false)
      }

    } catch (e) {
      setLoggedIn(false)
    }
  }

  return (
    <>
      <TawkToWidget />
      {popupState && (
        <LoginPopup
          onCloseButtonClick={() => {
            dispatch(popupValue(false));
          }}
        />
      )}
      <BrowserRouter>
        <Suspense fallback={<LoadingSkeleton />}>
          <Routes>
            <Route path="/" element={<LazyLayout />}>
              <Route index element={<LazyHome />} />
              <Route path="/about" element={<LazyAbout />} />
              <Route path="/rental-cars" element={<LazyCarsList />} />
              <Route path="/rental-car-details" element={<LazyBookCab />} />
              <Route path="/help" element={<LazyHelp />} />
              <Route path="/term-conditions" element={<LazyTerms />} />
              <Route path="/privacy-policy" element={<LazyPrivacy />} />
              <Route path="/contact-us" element={<LazyContact />} />
              <Route path="/add-partner" element={<LazyAddPartner />} />
              <Route path="/myaccount" element={loggedIn !== null && !loggedIn ? <Navigate to="/" /> : <LazyMyAccount />} />
              {/* <Route path="/myaccount" element={<MyAccount />} /> */}
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Flip}>
      </ToastContainer>
    </>
  );
}
export default App;
